// src/App.js
import React from 'react';
import { Layout, Menu } from 'antd';
import { UploadOutlined, FileSearchOutlined } from '@ant-design/icons';
import UploadForm from './UploadForm';
import DisplayResume from './DisplayResume';
import styled from 'styled-components';
import Landing from './Landing';
import { BrowserRouter, Routes ,Route} from 'react-router-dom';
import FileUpload from './UploadForm';

const { Header, Content } = Layout;

const AppContainer = styled(Layout)`
  min-height: 100vh;
`;

const AppHeader = styled(Header)`
  background: #001529;
  color: #fff;
`;

const AppContent = styled(Content)`
  padding: 50px;
  background: #f0f2f5;
`;

const App = () => {
  return (
    // <AppContainer>
    //   <AppHeader>
        
    //     <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
    //       <Menu.Item key="1" icon={<UploadOutlined />}>
    //         Upload Resume
    //       </Menu.Item>
    //       <Menu.Item key="2" icon={<FileSearchOutlined />}>
    //         Retrieve Resume
    //       </Menu.Item>
    //     </Menu>
    //   </AppHeader>
    //   <AppContent>
    //     <UploadForm />
    //     <DisplayResume />
    //   </AppContent>
    // </AppContainer>
    <>
    <BrowserRouter>
    <Routes>
      <Route path={'/'} element={<Landing/>} />
      <Route path='/upload' element={<FileUpload/>}/>
    </Routes>
    </BrowserRouter>

    </>
  );
};

export default App;
