import React, { useState } from 'react';
import axios from 'axios';

const FileUpload = () => {
    const [file, setFile] = useState(null);
    const [certificateNumber, setCertificateNumber] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleCertificateNumberChange = (e) => {
        setCertificateNumber(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!file) {
            alert('Please select a file to upload');
            return;
        }

        const formData = new FormData();
        formData.append('resume', file);
        formData.append('certificateNumber', certificateNumber);

        try {
            await axios.post('https://uk-backend-e5qy.vercel.app/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('File uploaded successfully');
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Failed to upload file');
        }
    };

    return (
        <div className="file-upload-container">
            <h1 className="upload-heading">File Upload</h1>
            <form className="file-upload-form" onSubmit={handleSubmit}>
                <input
                    type="file"
                    onChange={handleFileChange}
                    className="file-input"
                />
                <input
                    type="text"
                    value={certificateNumber}
                    onChange={handleCertificateNumberChange}
                    placeholder="Certificate Number"
                    className="certificate-input"
                />
                <button type="submit" className="upload-button">
                    Upload
                </button>
            </form>
        </div>
    );
};

export default FileUpload;
